import './style.css'
import { Grid, Typography } from '@mui/material';
import welcome from '../../assests/images/welcoming3.png'
import Colors from "../../theme/color-palette";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function WelcomeSection({ isMobile }: { isMobile: boolean }) {
    const whatsapp = ["W", "H", "A", "T", "S", "U", "P"]
    const instagram = ["I", 'N', 'S', "T", 'A', 'G', 'R', 'A', 'M']
    const contacts = ['C', 'A', 'L', 'L']
    return (<>
        {isMobile ? (<>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}> <img src={welcome} width={'80%'} height={"100%"} /></div>
            <Grid container spacing={1} marginTop={'20px'} padding={'10px'}>
                <Grid item xs={10}>
                    <Typography variant="h6" textAlign="center" fontFamily="Century Gothic" fontSize="18px" letterSpacing="4px">WELCOME TO</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h1" textAlign="right" fontSize="70px" fontWeight="bold">Crissy</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h1" textAlign="center" fontSize="70px" fontWeight="bold">Nail Bar</Typography>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={"center"} padding={'5px'} textAlign={'center'}>
                    <Typography variant="body1" fontFamily="Century Gothic" fontSize="4vw" >
                        Step into our nail bar located in Kaslik- Jounieh
                        and experience excellence in every detail.
                        Our dedicated team ensures impeccable
                        hygiene standards, using
                        sterilized tools and brand new equipment for every client
                        Using professional techniques, we deliver flawless
                        manicures and pedicures that leave you feeling
                        pampered and polished. Additionally, we offer waxing,
                        eyebrow threading, eyebrow lamination, and makeup
                        services to complete your beauty routine.
                        Relax in our inviting environment as we cater to your
                        beauty needs with precision and care.
                    </Typography>
                </Grid>
            </Grid>
        </>)
            :
            (<div style={{ overflow: 'auto',  display: isMobile ? 'grid' : "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: 'grid', marginTop: "-5rem", marginLeft: "10px" , position:"fixed" , zIndex:1000}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='socialLetters'>
                            {instagram.map((i) => (<a className='letters' href="https://www.instagram.com/crissynailbar" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D' }}>{i}</a>))}
                        </div>
                        <div><InstagramIcon />  </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>
                        <div className='socialLetters'>  {whatsapp.map((w) => (<a className='letters' href="https://wa.me/70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D' }}>{w}</a>))}</div>
                        <div><WhatsAppIcon /> </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>
                        <div className='socialLetters'>
                            {contacts.map((c) => (<a className='letters' href="tel:70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D' }}>{c}</a>))}
                        </div>
                        <div><AccountCircleIcon />  </div>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h6" textAlign="center" fontFamily="Century Gothic" fontSize="18px" letterSpacing="4px">WELCOME TO</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h1" textAlign="center" fontSize="70px" fontWeight="bold">Crissy</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h1" textAlign="center" fontSize="70px" fontWeight="bold">Nail Bar</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Typography variant="body1" fontFamily="Century Gothic" fontSize="1vw" >
                            Step into our nail bar located in Kaslik - Jounieh,<br />
                            and experience excellence in every detail. Our dedicated<br />
                            team ensures impeccable hygiene standards, using<br />
                            sterilized tools and brand new equipment for every client.<br />
                            Using professional techniques, we deliver flawless<br />
                            manicures and pedicures that leave you feeling<br />
                            pampered and polished. Additionally, we offer waxing,<br />
                            eyebrow threading, eyebrow lamination, and makeup<br />
                            services to complete your beauty routine.<br />
                            Relax in our inviting environment as we cater to your<br />
                            beauty needs with precision and care.
                        </Typography>
                    </Grid>


                </Grid>
                <div style={{ marginTop: "8px", transform: "translate(-30px,0px)" }}> <img src={welcome} width={'100%'} height={"100%"} /></div>
            </div >)}
    </>)
}