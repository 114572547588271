import './style.css'
import leafs from '../../assests/images/leafs.svg';
import { Grid, ListItem, List, } from '@mui/material';

export default function PriceList({ isMobile, pricelistRef }: { isMobile: any, pricelistRef: any }) {
    return (<>

        <div ref={pricelistRef} className='price-leaf-background' style={{ backgroundImage: `url(${leafs})`, padding: "20px" }}>
            <Grid container sx={{ transform: isMobile ? "translatex(0px)" : "translatex(20px)" }} >
                <Grid item xs={isMobile ? 12 : 5} sm={5} md={5}  >
                    <List sx={{ letterSpacing: "1.7px", color: "gray", fontFamily: "Century Gothic", fontSize: "14px", fontWeight: "600" }}>
                        <ListItem sx={{ textAlign: isMobile ? "center" : " ", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px", color: 'black' }}>Nails Services</ListItem>
                        <ListItem >Mani Pedi Combo (regular): $20</ListItem>
                        <ListItem>Mani (gelish) Pedi (regular) Combo: $26</ListItem>
                        <ListItem>Mani Pedi Combo (gelish): $32</ListItem>
                        <ListItem>Pose hands: $4</ListItem>
                        <ListItem>Regular Mani: $10</ListItem>
                        <ListItem>Pose Gelish: $10</ListItem>
                        <ListItem>Mani Gelish: $16</ListItem>
                        <ListItem>Press Ons + Gelish: $23</ListItem>
                        <ListItem>Press Ons Nail Fix: $3</ListItem>
                        <ListItem>Gel/Acrylic Full Set: $55</ListItem>
                        <ListItem>Gel/Acrylic Refill (long): $33</ListItem>
                        <ListItem>Gel/Acrylic Refill (short): $25</ListItem>
                        <ListItem>Gel Nail fix: $6</ListItem>
                        <ListItem>Dipping: $4</ListItem>
                    </List>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3} sm={3} md={3}>
                    {/* <Typography variant="h5" sx={{ textAlign: isMobile ? "center" : "", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px" }}>Waxing</Typography> */}
                    <List sx={{ letterSpacing: "1.7px", color: "gray", fontFamily: "Century Gothic", fontSize: "14px", fontWeight: "600" }}>
                        <ListItem sx={{ textAlign: isMobile ? "center" : "", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px", color: "black" }}>Waxing</ListItem>
                        <ListItem>Full Arms: $4</ListItem>
                        <ListItem>Half Arms: $3</ListItem>
                        <ListItem>Under Arms: $2</ListItem>
                        <ListItem>Full Legs: $6</ListItem>
                        <ListItem>Half Legs: $4</ListItem>
                        <ListItem>Back: $4</ListItem>
                        <ListItem>Half Back: $2</ListItem>
                        <ListItem>Belly: $4</ListItem>
                        <ListItem>Half Belly: $2</ListItem>
                        <ListItem>Bikini Line: $3</ListItem>
                        <ListItem>Full Body: $20</ListItem>
                    </List>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3} sm={3} md={3}>
                    <List sx={{ letterSpacing: "1.7px", color: "gray", fontFamily: "Century Gothic", fontSize: "14px", fontWeight: "600" }}>

                        <ListItem sx={{ textAlign: isMobile ? "center" : "", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px", color: "black" }}>Eyebrow & Lashes</ListItem>
                        <ListItem>Eyebrow Threading: $3</ListItem>
                        <ListItem>Eyebrow + Upper Lip Threading: $4</ListItem>
                        <ListItem>Eyebrow Waxing: $4</ListItem>
                        <ListItem>Eyebrow + Upper Lip Waxing: $6</ListItem>
                        <ListItem>Full Face Waxing: $9</ListItem>
                        <ListItem>Eyebrow Lamination: $35</ListItem>
                        <ListItem>Eyebrow/Eyelash Tinting: $5</ListItem>
                        <ListItem>Fake Lashes: $5</ListItem>
                        <ListItem>Individual Fake Lashes: $7</ListItem>
                    </List>
                    {/* <Typography variant="h5" textAlign={"center"} sx={{ textAlign: isMobile ? "center" : "", marginTop: "20px", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px" }}>Makeup</Typography> */}
                    <List sx={{ letterSpacing: "1.7px", color: "gray", fontFamily: "Century Gothic", fontSize: "14px", fontWeight: "600" }}>
                        <ListItem sx={{ textAlign: isMobile ? "center" : "", letterSpacing: "1.7px", fontFamily: "Century Gothic", fontWeight: "bold", fontSize: "20px", color: "black" }}>Makeup</ListItem>
                        <ListItem>Full Makeup: $50</ListItem>
                        <ListItem>Eyeshadow & Lashes: $30</ListItem>
                    </List>
                </Grid>
            </Grid>
        </div >



    </>)
}

