import { Button, IconButton, Grid, Avatar, Typography, Card, Box, Container, useTheme, Toolbar, List, Link, TextField } from '@mui/material';
import './style.css'
import Colors from "../../theme/color-palette";
import leafs from '../../assests/images/leafs.svg';
import contact from '../../assests/images/contact.png';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';


export default function OurContact({ isMobile, contactRef }: { isMobile: boolean, contactRef: any }) {

    return (<>
        <div ref={contactRef} className='our-work-leaf-background' style={{ backgroundImage: `url(${leafs})` }}>
        <div style={{ padding: "20px", textAlign: isMobile ? 'left' : 'left' }}> <Typography variant={isMobile ? 'h4' : "h3"} style={{ fontFamily: "Century Gothic", transform: isMobile ? "translateX(10px)" : "translateX(0rem)" }}> Get In Touch </Typography></div>
            <Container maxWidth="sm" style={{ width: isMobile ? "300px" : "", marginBottom: "20px", backgroundColor: Colors.white, border: "solid 1px #8CC7D0", marginTop: '30px', display: "grid", justifyContent: "center" }} >
                <Grid container spacing={1} padding={'10px'}>
                    <Grid item xs={12} sm={12} md={6} xl={6} fontFamily={'Century Gothic'} fontSize={'16px'}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p><a href="https://maps.app.goo.gl/nLcL998nkMLnQ91u5" target="_blank" rel="noopener noreferrer"><FmdGoodOutlinedIcon sx={{ color: "#8CC7D0", fontSize: "30px" }} /> </a></p>
                            <a href="https://maps.app.goo.gl/nLcL998nkMLnQ91u5" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D', marginLeft: '10px' }}>
                                <p color={'#3D3D3D'}> Kaslik, Jounieh, Rabih Daccache Beauty Salon, 1st Floor</p>
                            </a>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p> <a href="tel:70207733" target="_blank" rel="noopener noreferrer"><LocalPhoneOutlinedIcon sx={{ color: "#8CC7D0", fontSize: "30px" }} /></a> </p>
                            <Typography variant="body1" color="#3D3D3D">
                                <a href="tel:70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D', fontSize: "15px" }}>
                                    70-207733
                                </a>
                            </Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p>   <a href="https://wa.me/70207733" target="_blank" rel="noopener noreferrer"> <WhatsAppIcon sx={{ color: "#8CC7D0", fontSize: "30px" }} /></a> </p>
                            <Typography variant="body1" color="#3D3D3D">
                                <a href="https://wa.me/70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D', fontSize: "15px" }}>
                                    70-207733
                                </a>
                            </Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p> <a href="https://www.instagram.com/crissynailbar" target="_blank" rel="noopener noreferrer"><InstagramIcon sx={{ color: "#8CC7D0", fontSize: "30px" }} /></a> </p>
                            <Typography variant="body1" color="#3D3D3D">
                                <a href="https://www.instagram.com/crissynailbar" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#3D3D3D', fontSize: "15px" }}>
                                    @crissynailbar
                                </a>
                            </Typography>

                        </div>
                        {/* <div className='services-container' style={{ background: 'linear-gradient(to bottom, white 40%, #FBD4D5 60%)', }}></div> */}
                    </Grid>
                    {isMobile === false && (<Grid item xs={12} sm={6}>
                        <img src={contact} alt="Contact" style={{ width: '100%', height: 'auto' }} />
                    </Grid>)}
                </Grid>

            </Container>

        </div>


    </>)
}