import nails from '../../assests/images/nails.png'
import waxing from '../../assests/images/waxing.png'
import eyebrows from '../../assests/images/eyebrows.png'
import nail from '../../assests/images/Mask Group 27.png'
export interface ServicesTypes {
    id: string,
    name: string,
    image: string,
  

}


export const Services: ServicesTypes[] = [
    {
        id: "1",
        name: 'NAILS',
        image: nails,
        

    },
    {
        id: "3",
        name: 'EYEBROWS',
        image: eyebrows,
        

    },
 
    {
        id: "2",
        name: 'WAXING',
        image: waxing,
      

    },
 
]