import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import { Link } from '@mui/material';
import logo from '../../assests/images/logo crissy -FINAL.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Colors from '../../theme/color-palette';
import './style.css'

function NavBar({ isMobile, scrollToSection, aboutRef, servicesRef, galleryRef, contactRef, pricelistRef }: { isMobile: boolean, scrollToSection: any, aboutRef: any, servicesRef: any, galleryRef: any, contactRef: any, pricelistRef: any }) {
    const pages = ['Services', 'Price lists', 'Gallery', 'Contact'];
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className={"navbarContainer"} style={{ background: Colors.lightPink, justifyContent: isMobile ? "center" : "space-evenly" }}>
                <div><img src={logo} width={isMobile ? "40px" : '120px'} height={isMobile ? "40px" : '70px'} /></div>
                <div>
                    {pages.map((page, index) => (
                        <Link
                            key={index}
                            className='navLinks'
                            to="/"
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            onClick={() => scrollToSection(index === 0 ? servicesRef : index === 1 ? pricelistRef : index === 2 ? galleryRef : contactRef)}
                            style={{
                                color: Colors.black,
                                fontSize: isMobile ? "13px" : "17px",
                                borderBottom: hoveredIndex === index ? `3px solid ${Colors.pink}` : 'none',

                            }}
                        >
                            {page}
                        </Link>
                    ))}
                </div>
                {!isMobile && (
                    <div >
                        <a href="https://wa.me/70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: "bold", color: Colors.black, fontSize: "15px" }}>  <u>BOOK APPOINTMENT<CalendarMonthIcon sx={{ fontSize: "17px" }} /></u></a>
                    </div>
                )}
            </div >
            {isSticky && (
                <div className='sticky-book-appointment' style={{ textAlign: "center" }}>
                    <a href="https://wa.me/70207733" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: "bold", color: Colors.black, fontSize: "15px" }}>  <u>BOOK APPOINTMENT<CalendarMonthIcon sx={{ fontSize: "17px" }} /></u></a>
                </div>
            )}


        </>
    )
}
export default NavBar;
