import './style.css'
import { Grid, Typography, Container, Dialog, DialogContent, Button } from '@mui/material';
import Colors from "../../theme/color-palette";
import gal1 from '../../assests/images/gallery/gal1.jpg';
import gal2 from '../../assests/images/gallery/gal2.jpg';
import gal3 from '../../assests/images/gallery/gal3.jpg';
import gal4 from '../../assests/images/gallery/gal4.jpg';
import gal5 from '../../assests/images/gallery/gal5.jpg';
import leafs from '../../assests/images/leafs.svg';
import work1 from '../../assests/images/works/work1.png';
import work2 from '../../assests/images/works/work2.png'
import work3 from '../../assests/images/works/work3.png'
import work4 from '../../assests/images/works/work4.png'
import work5 from '../../assests/images/works/work5.png'
import work6 from '../../assests/images/works/work6.png'
import work7 from '../../assests/images/works/work7.png'
import work8 from '../../assests/images/works/work8.png'
import work9 from '../../assests/images/works/work9.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css/effect-fade';

export default function OurWork({ isMobile, galleryRef }: { isMobile: boolean, galleryRef: any }) {
    const works = [work1, work4, work5, work2, work3, work4, work5, work6, work7, work8, work9,]
    const gallery = [gal5, gal1, gal2, gal3, gal4]
    const [open, setOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    // const handleClickOpen = (index) => {
    //     setCurrentImageIndex(index);
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % gallery.length);
    };
    const [selectedImage, setSelectedImage] = useState(0);
    const handleClickOpen = (image: any) => { setSelectedImage(image); setOpen(true); };
    const handleClose = () => { setOpen(false); };
    return (

        <>

            {/* Gallery design  */}
            <div ref={galleryRef} className='our-work-leaf-background' style={{ backgroundImage: `url(${leafs})`, }}>
                <div style={{ padding: "20px", textAlign: isMobile ? 'left' : 'left' }}> <Typography variant={isMobile ? 'h4' : "h3"} style={{ fontFamily: "Century Gothic", transform: isMobile ? "translateX(10px)" : "translateX(0rem)" }}>     Our Gallery </Typography></div>
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }} >
                    {gallery.map((image, index) => (
                        <div >
                            <img
                                onClick={() => handleClickOpen(index)}
                                src={image}
                                alt={`Image ${index + 1}`}
                                style={{ width: isMobile ? '100px' : '250px', height: isMobile ? '100px' : '250px' }}
                            />
                        </div>
                    ))}
                </div>

                <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <DialogContent sx={{ padding: 0, position: 'relative' }}>
                        <img src={gallery[currentImageIndex]} alt="Selected" style={{ width: '100%', height: '100%' }} />
                        <Button
                            variant="contained"
                            onClick={handleNext}
                            style={{
                                backgroundColor: Colors.pink,
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)'
                            }}
                        >
                            <ArrowForwardIcon sx={{ color: Colors.white, fontSize: "20px", fontWeight: "bold" }} />
                        </Button>
                        <div className="slider-controler" >
                            <div className="swiper-button-next slider-arrow" onClick={handleNext}>
                                <ArrowForwardIcon style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px', color: Colors.white,
                                    transform: 'translateY(-50%)'
                                }} />
                            </div>

                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div style={{ padding: "20px", textAlign: isMobile ? 'left' : 'center' }}> <Typography variant={isMobile ? 'h4' : "h3"} style={{ fontFamily: "Century Gothic", transform: isMobile ? "translateX(10px)" : "translateX(0rem)" }}>     Some of our Work ... </Typography></div>
            <div className="container">
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}

                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        // clickable: true,
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper_container"
                >
                    <SwiperSlide>
                        <img src={work9} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work2} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work3} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work9} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work2} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work3} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work5} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work6} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work7} alt="slide_image" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={work8} alt="slide_image" />
                    </SwiperSlide>

                    <div className="slider-controler" >
                        <div className="swiper-button-prev slider-arrow">
                            <ArrowBackIcon sx={{ color: Colors.white }} />
                        </div>

                        <div className="swiper-button-next slider-arrow">
                            <ArrowForwardIcon sx={{ color: Colors.white }} />
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </Swiper>
            </div>

        </>
    )

}




































































