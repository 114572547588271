import './style.css'
import { Services } from './services-data'
import leafs from '../../assests/images/leafs.svg';
import { Typography, Card } from '@mui/material';


export default function OurServices({ isMobile, servicesRef }: { isMobile: boolean, servicesRef: any }) {

    return (
        <div ref={servicesRef} className='services-container' style={{ background: isMobile ? '' : 'linear-gradient(to bottom, white 50%, #FBD4D5 50%)' }}>
            <div className='leafs-background' style={{ backgroundImage: `url(${leafs})` }}></div>
            <div style={{ padding: "20px", textAlign: isMobile ? 'left' : 'left' }}> <Typography variant={isMobile ? 'h4' : "h3"} style={{ fontFamily: "Century Gothic", transform: isMobile ? "translateX(10px)" : "translateX(0rem)" }}> Our Services </Typography></div>
            {isMobile ? (<>
                <div style={{ padding: "10px", marginTop: "30px" }}>
                    {Services.map((service, index) => (
                        <div key={index} className='mob-card-container'>
                            <Card className='mob-services-card'>
                                <img src={service.image} width={"100%"} height={"100%"} />
                                <p style={{ fontFamily: 'Century Gothic', fontWeight: "500", }}> {service.name}</p>
                            </Card>
                            <img src={leafs} className='mob-card-leafs' />
                        </div>
                    ))}
                </div>
            </>) : (<>
                <div style={{ background: 'linear-gradient(to bottom, white 50%, #FBD4D5 50%)', padding: "15px", marginTop: "30px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "10px" }}>
                        {Services.map((service, index) => (
                            <>
                                <Card className='desktop-service-card' sx={{ transform: index % 2 === 0 ? "translateY(0rem)" : "translateY(-4rem)" }}>
                                    <img src={service.image} width="100%" height="100%" />
                                    <p style={{ fontWeight: "500", fontSize: "16px", fontFamily: 'Century Gothic' }}> {service.name}</p>

                                </Card>

                            </>))}
                    </div>
                </div>
            </>)}

        </div >
    )
}