import NavBar from "../../components/navbar/navbar";
import './style.css'
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Grid, Avatar, Typography, Card, Box, Container, useTheme, Toolbar, List, Link } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Colors from "../../theme/color-palette";
import WelcomeSection from "../welcome-section/welcome-section";
import AboutUs from "../about-section/about-section";
import OurServices from "../services-section/our-services";
import OurWork from "../our-work-section/our-work";
import OurContact from "../contact-section/our-contact";
import PriceList from "../price-list-section/price-list";
function HomePages() {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 845);
        };
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);
        return () => {
            window.removeEventListener("resize", checkScreenSize);
        };
    }, []);
    const aboutRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const galleryRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const pricelistRef = useRef<HTMLDivElement>(null);
    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div style={{ background: 'linear-gradient(to right, white 70%, #FBD4D5 30%)', height: '100%' }}>
                <div>
                    <NavBar
                        isMobile={isMobile}
                        scrollToSection={scrollToSection}
                        aboutRef={aboutRef}
                        servicesRef={servicesRef}
                        galleryRef={galleryRef}
                        contactRef={contactRef}
                        pricelistRef={pricelistRef}
                    />
                </div>
                {isMobile ? (
                    <div className={isSticky ? 'sticky-book-appointment' : 'boock-app'} style={{ textAlign: 'center' }}>
                        <a
                            href="https://wa.me/70207733"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', fontWeight: 'bold', color: Colors.black }}
                        >
                            <u>
                                BOOK APPOINTMENT
                                <CalendarMonthIcon sx={{ fontSize: '17px' }} />
                            </u>
                        </a>
                    </div>
                ) : (
                    ''
                )}

                <div><WelcomeSection isMobile={isMobile} />  </div>
            </div>
            <div> <OurServices isMobile={isMobile} servicesRef={servicesRef} /> </div>
            <div style={{ padding: "20px", textAlign: isMobile ? 'left' : 'left' }}> <Typography variant={isMobile ? 'h4' : "h3"} style={{ fontFamily: "Century Gothic", transform: isMobile ? "translateX(10px)" : "translateX(-0rem)" }}> Our pricelist </Typography></div>
            <div> <PriceList isMobile={isMobile} pricelistRef={pricelistRef} /></div>
            <div> <OurWork isMobile={isMobile} galleryRef={galleryRef} /></div>
            <div> <OurContact isMobile={isMobile} contactRef={contactRef} /></div>

        </>
    )
}

export default HomePages;  